import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchProductById } from "../actions/ProductActions";
import Product from "../components/Product";
import Loader from "../components/Loader";

const ProductView = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { selectedProduct, loading, error } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(fetchProductById(id));
  }, [dispatch, id]);

  if (loading) return <Loader />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      {selectedProduct ? (
        <Product product={selectedProduct} />
      ) : (
        <div>Product not found</div>
      )}
    </div>
  );
};

export default ProductView;
