import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addAddress, getAddressbyUserId } from '../actions/addressActions';
import { toast } from 'react-toastify';
import '../styles/checkout.css';

const ShippingInfo = ({ data, setData }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    country: 'Rwanda',
    city: 'Kigali City',
    address: '',
    postal_code: '0000',
    address_type: 'billing',
    is_default: true
  });

  useEffect(() => {
    const fetchAddress = async () => {
      console.log('Effect triggered');
      try {
        console.log('About to dispatch getAddressbyUserId');
        const addresses = await dispatch(getAddressbyUserId());
        console.log('Addresses received:', addresses);
        if (addresses && addresses.length > 0) {
          const address = addresses[0];
          setForm(address);
        }
      } catch (error) {
        console.error('Error fetching addresses:', error);
      }
    };
    fetchAddress();
  }, [dispatch]);

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setForm(prevForm => ({
      ...prevForm,
      [e.target.name]: value
    }));
    if (setData && (e.target.name === 'city' || e.target.name === 'country' || e.target.name === 'address')) {
      setData({
        ...data,
        city: e.target.name === 'city' ? value : form.city,
        country: e.target.name === 'country' ? value : form.country,
        houseAddress: e.target.name === 'address' ? value : form.address,
        district: e.target.name === 'city' ? value : form.city
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const addressData = {
      country: form.country,
      city: form.city,
      address: form.address,
      postal_code: form.postal_code,
      address_type: form.address_type,
      is_default: form.is_default
    };
    
    try {
      dispatch(addAddress(addressData))
        .then(() => {
          toast.success('Address saved successfully!');
        })
        .catch((error) => {
          console.error('Error saving address:', error);
        });
    } catch (error) {
      console.error('Error dispatching action:', error);
    }
  };

  return (
    <div className="formSection">
    
      <div className="form-row">
        <div className="form-group">
          <label className="checkoutLabel">Country</label>
          <select
            name="country"
            value={form.country}
            onChange={handleChange}
            className="checkoutInput"
            required
          >
            <option value="Rwanda">Rwanda</option>
          </select>
        </div>

        <div className="form-group">
          <label className="checkoutLabel">City</label>
          <select
            name="city"
            value={form.city}
            onChange={handleChange}
            className="checkoutInput"
            required
          >
            <option value="Kigali City">Kigali City</option>
          </select>
        </div>
      </div>

      <div className="form-group">
        <label className="checkoutLabel">Street Address</label>
        <input
          type="text"
          name="address"
          value={form.address}
          onChange={handleChange}
          className="checkoutInput"
          placeholder="e.g., KN 2 Avenue"
          required
        />
      </div>

      <div className="form-row">
        <div className="form-group">
          <label className="checkoutLabel">Postal Code</label>
          <input
            type="text"
            name="postal_code"
            value={form.postal_code}
            onChange={handleChange}
            className="checkoutInput"
            readOnly
          />
        </div>

        <div className="form-group">
          <label className="checkoutLabel">Address Type</label>
          <select
            name="address_type"
            value={form.address_type}
            onChange={handleChange}
            className="checkoutInput"
          >
            <option className="checkoutInput-option" value="billing">Billing</option>
            <option className="checkoutInput-option" value="shipping">Shipping</option>
          </select>
        </div>
      </div>

      <div className="form-group checkbox-group">
        <label className="checkbox-label">
          <input
            type="checkbox"
            name="is_default"
            checked={form.is_default}
            onChange={handleChange}
          />
          Set as default address
        </label>
      </div>

      <div className="form-group">
        <button 
          type="button"
          className="checkout-btn-save"
          onClick={handleSubmit}
        >
          Save Address
        </button>
      </div>
    </div>
  );
};

export default ShippingInfo;
