import React, { useState } from "react";
import "../styles/filtersection.css";
import { FaPlus, FaMinus, FaTimes } from "react-icons/fa";
import ReactSlider from "react-slider";
import { useDispatch, useSelector } from 'react-redux';

const FilterSection = () => {
  const [expandedSections, setExpandedSections] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const dispatch = useDispatch();
  const filters = useSelector(state => state.filters);

  const categories = [
    {
      name: "Product Category",
      options: ["Moisturizers", "Cleansers", "Toners", "Serums", "Essences"]
    },
    {
      name: "Skin Type",
      options: ["Combination & Normal", "Dry", "Oily", "Sensitive"]
    },
    {
      name: "Skin Concern",
      options: [
        "Acne",
        "Blackheads",
        "Brightening",
        "Deep Cleansing",
        "Fine Dust Removal",
        "Moisturising",
        "Soothing",
        "Visible Pores",
        "Well-aging"
      ]
    },
    {
      name: "Features",
      options: [
        "Moisturizing",
        "Anti-aging",
        "Low irritant",
        "Absorbs quickly",
        "Brightening",
      ]
    },
    {
      name: "Brand",
      options: ["9wishes", "Abib", "Acnes", "Acwell", "AESTURA", "AHC"]
    },
    {
      name: "Average Reviews",
      options: ["4★ & Up", "3★ & Up", "2★ & Up", "1★ & Up"]
    }
  ];

  const toggleSection = (section) => {
    setExpandedSections(prev => 
      prev.includes(section)
        ? prev.filter(s => s !== section)
        : [...prev, section]
    );
  };

  const handleFilterChange = (category, option) => {
    console.log('Applying filter:', category, option);
    
    setSelectedFilters(prev => {
      const newFilters = { ...prev };
      if (!newFilters[category]) {
        newFilters[category] = [];
      }
      
      if (newFilters[category].includes(option)) {
        newFilters[category] = newFilters[category].filter(item => item !== option);
        if (newFilters[category].length === 0) {
          delete newFilters[category];
        }
      } else {
        newFilters[category] = [...newFilters[category], option];
      }

      // Dispatch to Redux
      dispatch({
        type: `SET_${category.toUpperCase().replace(/\s+/g, '_')}`,
        payload: newFilters[category] || []
      });
      
      return newFilters;
    });
  };

  const handlePriceRangeChange = (values) => {
    console.log('Setting price range:', values);
    dispatch({
      type: 'SET_PRICE_RANGE',
      payload: { min: values[0], max: values[1] }
    });
  };

  const removeFilter = (category, option) => {
    setSelectedFilters(prev => {
      const newFilters = { ...prev };
      if (newFilters[category]) {
        newFilters[category] = newFilters[category].filter(item => item !== option);
        if (newFilters[category].length === 0) {
          delete newFilters[category];
        }
      }
      
      // Dispatch to Redux
      dispatch({
        type: `SET_${category.toUpperCase().replace(/\s+/g, '_')}`,
        payload: newFilters[category] || []
      });

      return newFilters;
    });
  };

  const clearAllFilters = () => {
    setSelectedFilters({});
    dispatch({ type: 'RESET_FILTERS' });
  };

  return (
    <div className="filter-section">
      <div className="filter-header">
        <div className="filter-title">
          <h3>REFINE</h3>
          <p className="clear-all" onClick={clearAllFilters}>Clear all</p>
        </div>
      </div>

      {/* Selected Filters Display */}
      {(Object.keys(selectedFilters).length > 0 || 
        filters.priceRange.min !== 0 || 
        filters.priceRange.max !== 50000) && (
        <div className="selected-filters">
          {Object.entries(selectedFilters).map(([category, options]) => (
            options.map(option => (
              <span key={`${category}-${option}`} className="selected-filter">
                {option}
                <FaTimes 
                  className="remove-filter" 
                  onClick={() => removeFilter(category, option)}
                />
              </span>
            ))
          ))}
          {(filters.priceRange.min !== 0 || filters.priceRange.max !== 50000) && (
            <span className="selected-filter">
              {filters.priceRange.min} - {filters.priceRange.max}
              <FaTimes 
                className="remove-filter" 
                onClick={() => handlePriceRangeChange([0, 500])}
              />
            </span>
          )}
        </div>
      )}

      {/* Price Range Section */}
      <div className="filter-category price-section">
        <div className="category-header">
          <h4>Price</h4>
        </div>
        <div className="category-options">
          <ReactSlider
            className="horizontal-slider"
            thumbClassName="thumb"
            trackClassName="track"
            min={0}
            max={50000}
            value={[filters.priceRange.min, filters.priceRange.max]}
            onChange={handlePriceRangeChange}
          />
          <div className="price-range-values">
            <span>{filters.priceRange.min} RWF</span> - <span>{filters.priceRange.max} RWF</span>
          </div>
        </div>
      </div>

      {/* Other filter categories */}
      {categories.map((category) => (
        <div key={category.name} className="filter-category">
          <div 
            className="category-header" 
            onClick={() => toggleSection(category.name)}
          >
            <h4>{category.name}</h4>
            {expandedSections.includes(category.name) ? 
              <FaMinus className="toggle-icon" /> : 
              <FaPlus className="toggle-icon" />
            }
          </div>
          {expandedSections.includes(category.name) && (
            <div className="category-options">
              {category.name === "Brand" && (
                <input
                  type="text"
                  placeholder="Search brand..."
                  className="brand-search-input"
                />
              )}
              {category.options.map((option) => (
                <label key={option} className="filter-option">
                  <input
                    type="checkbox"
                    className="filter-checkbox"
                    checked={selectedFilters[category.name]?.includes(option) || false}
                    onChange={() => handleFilterChange(category.name, option)}
                  />
                  <span className="option-text">{option}</span>
                  <span className="option-count">(25)</span>
                </label>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FilterSection;
