import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  loading: false,
  error: null,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartItems: (state, action) => {
      state.items = action.payload;
    },
    addItemToCart: (state, action) => {
      state.items.push(action.payload);
    },
    removeItemFromCart: (state, action) => {
      state.items = state.items.filter(item => item.cart_item_id !== action.payload);
    },
    updateCartItem: (state, action) => {
      const { cart_item_id, quantity } = action.payload;
      const itemToUpdate = state.items.find(item => item.cart_item_id === cart_item_id);
      if (itemToUpdate) {
        itemToUpdate.quantity = quantity;
      }
    },
    clearCart: (state) => {
      state.items = [];
    },
  },
});

export const { 
  setCartItems, 
  addItemToCart, 
  removeItemFromCart, 
  updateCartItem,
  clearCart 
} = cartSlice.actions;

export default cartSlice.reducer;
