import axios from 'axios';
import { setCartItems, addItemToCart, removeItemFromCart } from '../reducers/cartReducer';
import { API_URL } from '../variables';

export const fetchCart = (getImageUrl) => async (dispatch, getState) => {
  const { token, user } = getState().auth;  
  console.log('Auth state before fetching cart:', { token, user });             
  try {
    const response = await axios.post(`${API_URL}/api/orders/carts/get`, {user_id: user.user_id}, {
      headers: { Authorization: `${token}` },
    });
    const cartItems = response.data.cart_items.map((item) => ({
      cart_item_id: item.cart_item_id,
      product: {
        product_id: item.product.product_id,
        name: item.product.name,
        description: item.product.description || 'No description available', 
        price: parseFloat(item.price_at_purchase) || parseFloat(item.product.discounted_price) || parseFloat(item.product.base_price), 
        imageUrl: getImageUrl(item.product.image),
      },
      quantity: item.quantity,
    }));
    dispatch(setCartItems(cartItems));
  } catch (error) {
    console.error('Error fetching cart:', error);
  }
};

export const addToCart = (product) => async (dispatch, getState) => {
  const { token, user, isAuthenticated } = getState().auth;
  if (!isAuthenticated) {
    return false;
  }
  
  try {
    const formData = new FormData();
    formData.append('user_id', user.user_id);
    formData.append('product_id', product.product_id);
    formData.append('quantity', product.quantity || 1);
    
    if (product.option_id) formData.append('option_id', product.option_id);
    if (product.size_id) formData.append('size_id', product.size_id);

    const response = await axios.post(
      `${API_URL}/api/orders/carts/add`,
      formData,
      { 
        headers: { 
          'Authorization': token,
          'Content-Type': 'multipart/form-data'
        } 
      }
    );

    if (response.data.status === "success") {
      const cartItem = response.data.data;
      dispatch(addItemToCart(cartItem));
      return cartItem;
    } else {
      console.error("Error adding to cart:", response.data.message);
    }
  } catch (error) {
    console.error('Error adding to cart:', error.response?.data || error);
    return false;
  }
};

export const removeFromCart = (cartItemId) => async (dispatch, getState) => {
  const { token } = getState().auth;
  
  try {
    const formData = new FormData();
    formData.append('cart_item_id', cartItemId);

    const response = await axios.post(
      `${API_URL}/api/orders/carts/remove`,
      formData,
      {
        headers: {
          'Authorization': token,
        }
      }
    );

    if (response.data.status === "success") {
      dispatch(removeItemFromCart(cartItemId));
      dispatch(fetchCart());
    }
  } catch (error) {
    console.error('Error removing item from cart:', error.response || error);
  }
};

export const updateCart = (cartData) => async (dispatch, getState) => {
  const { token } = getState().auth;
  
  try {
    const formData = new FormData();
    formData.append('cart_item_id', cartData.cart_item_id);
    formData.append('product_id', cartData.product_id);
    formData.append('quantity', cartData.quantity);
    
    if (cartData.option_id) formData.append('option_id', cartData.option_id);
    if (cartData.size_id) formData.append('size_id', cartData.size_id);

    await axios.post(
      `${API_URL}/api/orders/carts/update`,
      formData,
      {
        headers: { 
          'Authorization': token,
        }
      }
    );
    dispatch(fetchCart());
    
  } catch (error) {
    console.error('Error updating cart:', error);
  }
};
  
