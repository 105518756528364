import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  token: null,
  isAuthenticated: false,
  isLoading: true,
  error: null
};

// load initial state from localStorage
try {
  const token = localStorage.getItem('authToken');
  const user = localStorage.getItem('user');
  
  if (token && user) {
    initialState.token = token;
    initialState.user = JSON.parse(user);
    initialState.isAuthenticated = true;
  }
} catch (error) {
  console.error('Error loading auth state:', error);
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setAuthData: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuthenticated = true;
      state.isLoading = false;
      localStorage.setItem('authToken', action.payload.token);
      localStorage.setItem('user', JSON.stringify(action.payload.user));
    },
    clearAuthData: (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      state.isLoading = false;
      localStorage.removeItem('authToken');
      localStorage.removeItem('user');
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  },
});

export const { setLoading, setAuthData, clearAuthData, setError } = authSlice.actions;
export default authSlice.reducer;
