import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import '../styles/notification.css';

const Notification = ({ message, isVisible, onClose, actionText, onActionClick }) => {
  const navigate = useNavigate();

  const navigateToCart = (e) => {
    e.stopPropagation();
    navigate('/cart');
    onClose();
  };

  const navigateToWishlist = (e) => {
    e.stopPropagation();
    navigate('/myaccount#wishlist');
    onClose();
  };

  const handleClose = (e) => {
    e.stopPropagation();
    onClose();
  };

  if (!isVisible) return null;

  return (
    <div className="notification-container" onClick={e => e.stopPropagation()}>
      <div className="notification-content">
        <div className="notification-icon">
          <AiOutlineCheck className="success-icon" />
        </div>
        <p className="notification-message">{message}</p>
        <div className="notification-actions">
          {actionText && (
            <button 
              className="notification-action-button" 
              onClick={actionText.toLowerCase().includes('cart') ? navigateToCart : navigateToWishlist}
            >
              {actionText}
            </button>
          )}
          <button 
            className="notification-close" 
            onClick={handleClose}
          >
            <AiOutlineClose />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Notification; 