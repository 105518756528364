const initialState = {
  priceRange: { min: 0, max: 1000 }, 
  category: [],
  skinType: [],
  skinConcern: [],
  brand: [],
  rating: 0
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PRICE_RANGE':
      return { ...state, priceRange: action.payload };
    case 'SET_CATEGORY':
      return { ...state, category: action.payload };
    case 'SET_SKIN_TYPE':
      return { ...state, skinType: action.payload };
    case 'SET_SKIN_CONCERN':
      return { ...state, skinConcern: action.payload };
    case 'SET_BRAND':
      return { ...state, brand: action.payload };
    case 'SET_RATING':
      return { ...state, rating: action.payload };
    default:
      return state;
  }
};

export default filterReducer;