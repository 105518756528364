import {
  GET_ADDRESS_REQUEST,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_FAILURE,
  ADD_ADDRESS_REQUEST,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_FAILURE,
  UPDATE_ADDRESS_REQUEST,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_FAILURE,
  DELETE_ADDRESS_REQUEST,
  DELETE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_FAILURE,
} from '../actions/addressActions';

const initialState = {
  addresses: [],
  loading: false,
  error: null,
};

const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADDRESS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_ADDRESS_SUCCESS:
      return { ...state, loading: false, addresses: action.payload };   
    case GET_ADDRESS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ADD_ADDRESS_REQUEST:
      return { ...state, loading: true, error: null };
    case ADD_ADDRESS_SUCCESS:
      return { ...state, loading: false, addresses: [...state.addresses, action.payload] }; 
    case ADD_ADDRESS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case UPDATE_ADDRESS_REQUEST:
      return { ...state, loading: true, error: null };
    case UPDATE_ADDRESS_SUCCESS:
      return { ...state, loading: false, addresses: state.addresses.map(address => 
        address.address_id === action.payload.address_id ? action.payload : address
      )};
    case UPDATE_ADDRESS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case DELETE_ADDRESS_REQUEST:
      return { ...state, loading: true, error: null };
    case DELETE_ADDRESS_SUCCESS:
      return { ...state, loading: false, addresses: state.addresses.filter(address => address.address_id !== action.payload) };
    case DELETE_ADDRESS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default addressReducer;






