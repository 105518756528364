import React, { useState, useEffect } from 'react';
import { FaUser, FaHeadset, FaSearch, FaTimes, FaBars, FaShoppingCart, FaHeart } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import '../styles/Header.css';
import AutoComplete from './AutoComplete';
import { selectProducts } from '../selectors/ProductSelector';
import { AiOutlineUser } from 'react-icons/ai';
import { fetchCategories } from '../actions/categoriesActions';
import MobileSidebar from './MobileSidebar';


const Header = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isMobileSearchVisible, setIsMobileSearchVisible] = useState(false);
  const [isMobileSidebarVisible, setIsMobileSidebarVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const products = useSelector(selectProducts);
  const { items: cartItems = [] } = useSelector((state) => state.cart);
  const { categories } = useSelector((state) => state.categories);
  

  const cartItemCount = cartItems.reduce((total, item) => total + (item.quantity || 1), 0);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const getSuggestions = (value) => {
    if (!value.trim()) {
      return [];
    }

    const inputValue = value.trim().toLowerCase();
    const productSuggestions = products
      .filter(product => product && product.name)
      .map(product => ({
        text: product.name,
        type: 'product',
        id: product.product_id
      }));
    const categorySuggestions = categories
      .filter(category => category && category.name)
      .map(category => ({
        text: category.name,
        type: 'category',
        id: category.category_id
      }));
    const allSuggestions = [...productSuggestions, ...categorySuggestions]
      .filter(item => item.text.toLowerCase().includes(inputValue))
      .slice(0, 5);

    return allSuggestions;
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const newSuggestions = getSuggestions(value);
    console.log('Setting suggestions:', newSuggestions); 
    setSuggestions(newSuggestions);
  };

  const handleSuggestionClick = (suggestion) => {
    if (suggestion.type === 'category') {
      navigate(`/category/${suggestion.id}`);
    } else {
      navigate(`/search?q=${suggestion.text}`);
    }
    
    setSearchTerm('');
    setSuggestions([]);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const trimmedSearchTerm = searchTerm.trim();
    if (trimmedSearchTerm) {
      navigate(`/search?q=${trimmedSearchTerm}`);
      setSearchTerm('');
      setSuggestions([]);
    }
  };

  const handleCategoryClick = (categoryId) => {
    navigate(`/category/${categoryId}`);
  };

  const toggleNavVisibility = () => {
    setIsNavVisible(!isNavVisible);
  };

  const handleCloseAutocomplete = () => {
    setSuggestions([]);
  };

  const toggleMobileSearch = () => {
    setIsMobileSearchVisible(!isMobileSearchVisible);
    if (isMobileSearchVisible) {
      setSearchTerm('');
      setSuggestions([]);
    }
  };

  const toggleMobileSidebar = () => {
    setIsMobileSidebarVisible(!isMobileSidebarVisible);
  };

  const renderAllCategories = () => (
    <div className="dropdown-content">
      <div className="dropdown-inner">
        <div className="parent-categories">
          {categories.map((cat) => (
            <button 
              key={cat.id} 
              className="parent-category"
              onClick={() => handleCategoryClick(cat.category_id)}
            >
              {cat.name}
            </button>
          ))}
        </div> 
      </div>
    </div>
  );
  const renderCategoryDropdown = (category) => (
    <div className="dropdown-content">
      <div className="dropdown-inner">
        {/* First column: Parent & Children */}
        <div className="parent-categories">
          <button className="parent-category">{category.name} <span>products</span></button>
          <div className="child-list">
            {category.children?.map((child) => (
              <button
                key={child.id}
                className="child-link"
                onClick={() => handleCategoryClick(child.category_id)}
              >
                {child.name}
              </button>
            ))}
          </div>
        </div>

        {/* Second section: Grandchildren columns */}
        <div className="grandchild-section">
          {category.children?.map((child) => (
            <div key={child.id} className="grandchild-column">
              <button 
                className="grandchild-title"
                onClick={() => handleCategoryClick(child.category_id)}
              >
                {child.name}
              </button>
              <div className="grandchild-items">
                {child.children?.map((grandchild) => (
                  <button
                    key={grandchild.id}
                    className="grandchild-item"
                    onClick={() => handleCategoryClick(grandchild.category_id)}
                  >
                    {grandchild.name}
                  </button>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <header>
      <div className="header-wrapper">
        <div className="container">
          <Link to="/" className="logo-container">
            <div className="logo">
              <span className="logo-text black">K</span>
              <span className="logo-text green">-Mart</span>
            </div>
            <span className="korean-products">Purchase Korean cosmetic directly</span>
          </Link>

          <form className="search-bar desktop-search" onSubmit={handleSearch}>
            <div className="search-input-wrapper">
              <input
                type="text"
                className="search-input"
                placeholder="Search for products or categories..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <button type="submit" className="search-button">
                <FaSearch className="search-icon" />
              </button>
              {suggestions.length > 0 && (
                <AutoComplete 
                  suggestions={suggestions}
                  searchTerm={searchTerm}
                  onSuggestionClick={handleSuggestionClick}
                  onClose={handleCloseAutocomplete}
                />
              )}
            </div>
          </form>

          <div className="icons-container">
            <button className="icon-item mobile-search-trigger" onClick={toggleMobileSearch}>
              <FaSearch className="icon" />
              <span>Search</span>
            </button>
            {user && user.username ? (
              <Link to="/myaccount" className="icon-item">
                <FaUser className="icon" />
                <span>{user.username}</span>
              </Link>
            ) : (
              <Link to="/signin" className="icon-item">
                <AiOutlineUser className="icon" />
                <span>Sign in</span>
              </Link>
            )}
            <Link to="/cart" className="icon-item">
              <div className="cart-icon-container">
                <FaShoppingCart className="icon" />
                {cartItemCount > 0 && (
                  <span className="header-cart-badge">{cartItemCount}</span>
                )}

              </div>
              <span>Cart</span>
            </Link>
            <Link to="/myaccount#wishlist" className="icon-item">
              <FaHeart className="icon" />
              <span>Wishlist</span>
            </Link>
            <Link to="/contactus" className="icon-item">
              <FaHeadset className="icon" />
              <span>Help</span>
            </Link>
          </div>

          <button className="menu-icon" onClick={toggleMobileSidebar}>
            <FaBars />
          </button>
        </div>
      </div>
      {isMobileSearchVisible && (
        <div className="mobile-search-overlay">
          <form onSubmit={handleSearch}>
            <div className="search-input-wrapper">
              <input
                type="text"
                className="search-input"
                placeholder="Search for products or categories..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <button type="button" className="close-search" onClick={toggleMobileSearch}>
                <FaTimes />
              </button>
              {suggestions.length > 0 && (
                <AutoComplete 
                  suggestions={suggestions}
                  searchTerm={searchTerm}
                  onSuggestionClick={handleSuggestionClick}
                  onClose={handleCloseAutocomplete}
                />
              )}
            </div>
          </form>
        </div>
      )}
      <nav className={`desktop-nav ${isNavVisible ? 'visible' : ''}`}>
        <button className="close-menu" onClick={toggleNavVisibility}>
          <FaTimes />
        </button>
        <div className="nav-container">
          <div className="nav-items">
            <div className="nav-item">
              <button className="nav-button">All Categories</button>
              {renderAllCategories()}
            </div>
            <Link to="/best" ><button>Best</button></Link>
            <Link to="/new"><button>New</button></Link>
            <Link to="/productspage"><button>Sale</button></Link>
            {categories.slice(0, 6).map((category) => (
              <div key={category.category_id} className="nav-item">
                <button className="nav-button">{category.name}</button>
                {renderCategoryDropdown(category)}
              </div>
            ))}
          </div>
          <div className="nav-items">
            <button>Membership</button>
          </div>
        </div>
      </nav>
      <MobileSidebar 
        isVisible={isMobileSidebarVisible}
        onClose={() => setIsMobileSidebarVisible(false)}
        categories={categories}
        handleCategoryClick={handleCategoryClick}
      />
    </header>
  );
};
export default Header;