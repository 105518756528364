import React from 'react';
import '../styles/specificinfo.css'; 

const SpecificItemInfo = ({ specificInfo, onClose }) => {
  return (
    <div className="specific-item-info-overlay">
      <div className="specific-item-info">
        <div className="info-header">
          <h2>Specific Item Info</h2>
          <button 
            onClick={onClose} 
            className="close-button"
            aria-label="Close"
          >
            ×
          </button>
        </div>

        <div className="info-content">
        {specificInfo && specificInfo.length > 0 ? (
            <ul>
              {specificInfo.map((info, index) => (
                <li key={index}>
                  <strong>{info.label}:</strong> {info.value}
                </li>
              ))}
            </ul>
          ) : (
            <p>No specific information available.</p>
          )}

        </div>
      </div>
    </div>
  );
};

export default SpecificItemInfo;