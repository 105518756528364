import React from 'react';
import Header from './Header'; 
import Footer from './Footer'; 
import '../styles/Layout.css';

const Layout = ({ children }) => {
  return (
    <div className="wrapper">
      <div className="content">
        <Header />
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
