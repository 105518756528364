import React, { useEffect } from 'react';
import '../styles/cart.css';
import CartItem from '../components/CartItem';
import OrderSummary from '../components/Ordersummary';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCart } from '../actions/cartActions';
import { useNavigate } from 'react-router-dom';
import { useImage } from "../contexts/ImageContext";
import emptycart from '../assets/images/emptycart.jpg'
import Loader from '../components/Loader';
import Recommendations from '../components/Recommendations';
import ProgressIndicator from '../components/ProgressIndicator';


const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);
  const { items: cartItems = [], loading: cartLoading } = useSelector((state) => state.cart);
  const { getImageUrl } = useImage();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchCart(getImageUrl));
    }
  }, [dispatch, isAuthenticated, getImageUrl]);

  if (authLoading || cartLoading) {
    return <Loader />;
  }

  const cartTotal = cartItems.reduce(
    (total, item) => total + (parseFloat(item.product.price) || 0) * (item.quantity || 1),
    0
  );

  return (
    <div className="cart-page">
      <ProgressIndicator currentStep={1} />

      <div className="cart-container">
        <div className="cart-items-section">
          <h2 className="cart-title">Shopping Cart</h2>
          {cartItems.length > 0 ? (
            <div className="cart-items-list">
              {cartItems.map((item) => (
                <CartItem
                  key={item.cart_item_id}
                  cartItemId={item.cart_item_id}
                  product={{
                    productId: item.product.product_id,
                    name: item.product.name,
                    description: <div dangerouslySetInnerHTML={{ __html: item.product.description }} />,
                    price: item.product.price,
                    quantity: item.quantity,
                    imageUrl: item.product.imageUrl || 'default-image-url',
                  }}
                />
              ))}
            </div>
          ) : (
            !cartLoading && (
              <div className="empty-cart">
                <img 
                  src={emptycart} 
                  alt="Empty Cart" 
                  className="empty-cart-image"
                />

                <h2 className="empty-cart-title">Your cart is empty</h2>
                <p className="empty-cart-text">Browse our products and find something you'll love!</p>
                <button 
                  className="continue-shopping-btn"
                  onClick={() => navigate('/')}
                >
                  Continue Shopping
                </button>
              </div>
            )
          )}
        </div>

        {cartItems.length > 0 && (
          <div className="cart-sidebar">
            <div className="promos-section">
              <h4>Promos & Offers</h4>
              <input type="text" placeholder="Enter promo code" className="promo-input" />
              <button className="apply-promo-button">Apply</button>
            </div>
            <OrderSummary cartTotal={cartTotal} />
          </div>
        )}
      </div>
      <div className="you-might-like-section">
        <Recommendations />
      </div>
    </div>
  );
};

export default Cart;
