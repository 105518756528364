export const formatMoney = (amount) => {
  const number = typeof amount === 'string' ? parseFloat(amount) : amount;
  if (isNaN(number)) return '0 RWF';
  const roundedNumber = Number(number.toFixed(1));
  const parts = roundedNumber.toString().split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.') + ' RWF';


};

export default formatMoney;