import React, { useState } from 'react';
import { AiOutlineShoppingCart, AiOutlineHeart, AiFillHeart, AiOutlineStar, AiFillStar, AiOutlinePlus, AiOutlineEye } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { addToWishlist, deleteFromWishlist } from '../actions/wishlistActions';
import { addToCart } from '../actions/cartActions';
import { useImage } from '../contexts/ImageContext';
import { useNavigate } from 'react-router-dom';
import Notification from './Notification';
import '../styles/new.css';
import formatMoney from './formatMoney';

const ProductCard = ({ product }) => {
  const {
    product_id,
    name,
    brand,
    base_price,
    discounted_price,
    product_rating,
    image,
    tags,
    oldPrice,
  } = product;
  const { getImageUrl } = useImage();
  const imageUrl = getImageUrl(image);
  const { isAuthenticated } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isInWishlist, setIsInWishlist] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationAction, setNotificationAction] = useState('');
  const isHotDeal = tags.includes('HOT DEAL');

  const handleAddToCart = (e) => {
    e.stopPropagation();
    e.preventDefault();
    
    if (!isAuthenticated) {
      navigate('/signin');
      return;
    }

    dispatch(addToCart({product_id, quantity: 1}));
    setNotificationMessage(`${name} added to cart successfully!`);
    setNotificationAction('View Cart');
    setShowNotification(true);
  };

  const handleWishlistToggle = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!isAuthenticated) {
      navigate('/signin');
      return;
    }

    if (isInWishlist) {
      dispatch(deleteFromWishlist(product_id));
      setNotificationMessage(`${name} removed from wishlist`);
      setNotificationAction('View Wishlist');
    } else {
      dispatch(addToWishlist(product_id));
      setNotificationMessage(`${name} added to wishlist`);
      setNotificationAction('View Wishlist');
    }
    setIsInWishlist(!isInWishlist);
    setShowNotification(true);
  };

  const handleProductView = (e) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/product/${product_id}`);
  };

  return (
    <div onClick={handleProductView}>
      <div className="new-product-card">
        <div
          className="new-product-image"
          style={{ backgroundImage: `url(${imageUrl})` }}
        >
          {tags && tags.length > 0 && (
            <span className={`new-product-tag ${tags[0]?.toLowerCase() === 'hot deal' ? 'hot-deal' : ''}`}>
              {tags[0]}
            </span>
          )}
          <div className="new-product-icons-overlay">
            <button className={`icon-button ${tags && tags[0]?.toLowerCase() === 'hot deal' ? 'hot-deal' : ''}`} onClick={handleWishlistToggle}>
              {isInWishlist ? <AiFillHeart /> : <AiOutlineHeart />}
            </button>
            <button className={`icon-button ${tags && tags[0]?.toLowerCase() === 'hot deal' ? 'hot-deal' : ''}`}>
              <AiOutlinePlus />
            </button>
            <button className={`icon-button ${tags && tags[0]?.toLowerCase() === 'hot deal' ? 'hot-deal' : ''}`} onClick={handleProductView}>
              <AiOutlineEye />
            </button>
          </div>
        </div>
        <div className="new-product-details">
          <div className="new-product-name-container">
            <h3 className="new-product-brand" dangerouslySetInnerHTML={{ __html: brand }}></h3>
            <AiOutlineShoppingCart onClick={handleAddToCart} className={`new-product-cart-icon ${isHotDeal ? 'hot-deal' : ''}`} />
          </div>
          <p className="new-product-name" dangerouslySetInnerHTML={{ __html: name}}></p>
          {oldPrice && <p className="new-product-old-price">{formatMoney(oldPrice)}</p>}
          <p className="new-product-old-price">{formatMoney(base_price)}</p>

          {base_price !== discounted_price && (
            <p className="new-product-price">
              {formatMoney(discounted_price)}
            </p>
          )}
          <div className="new-product-rating">
            {product_rating === 0 ? (
              <span className="new-product-rating"><AiOutlineStar className="outlined-star" /> No reviews yet </span>
            ) : (
              <span className="new-product-rating">
                {[...Array(5)].map((_, index) => (
                  <span key={index}>
                    {index < Math.floor(product_rating) ? (
                      <AiFillStar className="filled-star" />
                    ) : (
                      <AiOutlineStar className="outlined-star" />
                    )}
                  </span>
                ))}
              </span>
            )}
          </div>
        </div>
      </div>
      <Notification 
        message={notificationMessage}
        isVisible={showNotification}
        onClose={() => setShowNotification(false)}
        actionText={notificationAction}
        onActionClick={() => navigate(notificationAction.toLowerCase().includes('cart') ? '/cart' : '/wishlist')}
      />
    </div>
  );
};

export default ProductCard;
