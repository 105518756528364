import React from 'react';
import '../styles/ProgressIndicator.css';



const ProgressIndicator = ({ currentStep }) => {
  const steps = [
    { number: '01', text: 'Shopping Bag' },

    { number: '02', text: 'Checkout' },
    { number: '03', text: 'Order Complete' }
  ];

  return (
    <div className="progress-indicator">
      <div className="progress-line">
        <div className="progress-line-fill"></div>
      </div>
      <div className="progress-steps">
        {steps.map((step, index) => (
          <div 
            key={step.number}
            className={`step-item ${
              index + 1 === currentStep ? 'active' : ''
            } ${index + 1 < currentStep ? 'completed' : ''}`}
          >
            <div className="step-circle">{step.number}</div>
            <span className="step-text">{step.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressIndicator; 