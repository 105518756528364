import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWishlist, deleteFromWishlist } from '../actions/wishlistActions';
import { addToCart } from '../actions/cartActions';
import { useImage } from "../contexts/ImageContext";
import formatMoney from './formatMoney';
const Wishlist = () => {
  const dispatch = useDispatch();
  const { results = [], loading, error } = useSelector(state => state.wishlist);

  useEffect(() => {
    dispatch(fetchWishlist(1));
  }, [dispatch]);
  const { getImageUrl } = useImage();

  if (loading) return <p>Loading your wishlist...</p>;
  if (error) return <p>{error}</p>;

  console.log('Wishlist Results:', results);

  const handleRemove = (wishlist_id) => {
    dispatch(deleteFromWishlist(wishlist_id));
  };

  const handleAddToCart = (product) => {
    console.log('Product in Wishlist:', product);
    if (product && product.product_id) {
      dispatch(addToCart(product));
    } else {
      console.error('Product ID is undefined in Wishlist');
    }
  };

  return (
    <div className="myaccount-wish-list">
      <h2>Wish List</h2>
      {results.length > 0 ? (
        results.map((item) => {
          const { product } = item;
          if (!product) {
            return null; 
          }
          return (
            <div key={item.wishlist_id} className="wishlist-item">
              <img
                src={getImageUrl(product.image)}
                alt={product.name}
                className="wishlist-item-image"
              />
              <div className="wishlist-item-details">
                <p className="wishlist-item-title">{product.name}</p>
              </div>
              <div className="wishlist-item-price">
                <p className="price-current">{formatMoney(product.discounted_price)}</p>
                {product.base_price && product.base_price !== product.discounted_price && (
                  <p className="price-old">{formatMoney(product.base_price)}</p>
                )}

              </div>
              <div className="wishlist-item-manage">
                <button className="wishlist-add-to-bag" onClick={() => handleAddToCart(item)}>Add to Cart</button>
                <button className="wishlist-remove" onClick={() => handleRemove(item.wishlist_id)}>Remove</button>
              </div>
            </div>
          );
        })
      ) : (
        <p>Your wishlist is empty.</p>
      )}
      {results.length > 0 && (
        <p className="wishlist-remove-all">
          <i className="fa fa-trash"></i> Remove all item(s)
        </p>
      )}
    </div>
  );
};

export default Wishlist;
