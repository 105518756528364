import React, { useState, useEffect } from 'react';
import { FaTimes, FaStar, FaFire, FaTag, FaHeart, FaHome } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import '../styles/mobilesidebar.css';

function MobileSidebar({ isVisible, onClose }) {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { categories } = useSelector((state) => state.categories);
  const location = useLocation();

  useEffect(() => {
    if (isVisible && categories?.length > 0 && !selectedCategory) {
      setSelectedCategory(categories[0]);
    }
  }, [isVisible, categories]);

  const categoryIcons = {
    'Skincare': '🧴',
    'Makeup': '💄',
    'Bath & Body': '🛁',
    'Hair': '💇',
    'Face Masks': '😷',
    'Suncare': '☀️',
  };

  const getSubcategories = (category) => {
    if (!category?.children) return [];
    
    const groupedItems = category.children.map(child => ({
      name: child.name,
      items: child.children?.map(grandchild => ({
        name: grandchild.name,
        id: grandchild.category_id
      })) || []
    }));

    return groupedItems;
  };

  return (
    <div className={isVisible ? 'mobile-sidebar visible' : 'mobile-sidebar'}>
      <div className="close-button">
        <button onClick={onClose}>
          <FaTimes />
        </button>
      </div>
      
      <div className="categories-nav">
        {categories?.map((category) => (
          <div
            key={category.category_id}
            className={`category-item ${selectedCategory?.category_id === category.category_id ? 'active' : ''}`}
            onClick={() => {
              setSelectedCategory(category);
            }}
          >
            <span>{categoryIcons[category.name] || '📦'}</span>
            <span>{category.name}</span>
          </div>
        ))}
      </div>

      {selectedCategory && (
        <div className="subcategories-panel">
          <div className="subcategories-header">
            <h2 className="category-title">
              {selectedCategory.name} Products
            </h2>
          </div>
          
          {getSubcategories(selectedCategory).map((section) => (
            <div key={section.name}>
              <h3>{section.name}</h3>
              <div className="subcategories-grid">
                {section.items.map((item) => (
                  <Link 
                    key={item.id} 
                    to={`/category/${item.id}`}
                    className="subcategory-item"
                    onClick={onClose}
                  >
                    <span>{item.name}</span>
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}

      <div className={`bottom-nav-links ${isVisible ? 'hidden' : ''}`}>
        <Link 
          to="/" 
          className={`bottom-nav-item ${location.pathname === '/' ? 'active' : ''}`} 
          onClick={onClose}
        >
          <span><FaHome className='bottom-nav-icon' /></span>
          <span>Home</span>
        </Link>
        <Link 
          to="/best" 
          className={`bottom-nav-item ${location.pathname === '/best' ? 'active' : ''}`} 
          onClick={onClose}
        >
          <span><FaStar className='bottom-nav-icon' /></span>
          <span>Best</span>
        </Link>
        <Link 
          to="/new" 
          className={`bottom-nav-item ${location.pathname === '/new' ? 'active' : ''}`} 
          onClick={onClose}
        >
          <span><FaFire className='bottom-nav-icon' /></span>
          <span>New</span>
        </Link>
        <Link 
          to="/productspage" 
          className={`bottom-nav-item ${location.pathname === '/productspage' ? 'active' : ''}`} 
          onClick={onClose}
        >
          <span><FaTag className='bottom-nav-icon' /></span>
          <span>Sale</span>
        </Link>
        <Link 
          to="/myaccount#wishlist" 
          className={`bottom-nav-item ${location.pathname === '/myaccount' ? 'active' : ''}`} 
          onClick={onClose}
        >
          <span><FaHeart className='bottom-nav-icon' /></span>
          <span>Wishlist</span>
        </Link>
      </div>
    </div>
  );
}

export default MobileSidebar;
