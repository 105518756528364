import axios from 'axios';
import { API_URL } from '../variables';

export const fetchCategories = () => async (dispatch) => {
  dispatch({ type: 'FETCH_CATEGORIES_START' });
  
  try {
    const response = await axios.get(`${API_URL}/api/products/category/parent`);
    dispatch({
      type: 'FETCH_CATEGORIES_SUCCESS',
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: 'FETCH_CATEGORIES_FAILURE',
      payload: error.message,
    });
  }
}; 