import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import cartReducer from "./reducers/cartReducer";
import productReducer from "./reducers/ProductReducer";
import orderReducer from "./reducers/orderReducer";
import reviewReducer from "./reducers/reviewReducer";
import wishlistReducer from "./reducers/wishlistReducer";
import filterReducer from "./reducers/filterReducer";
import siteReducer from "./reducers/siteReducer";
import categoriesReducer from "./reducers/categoriesReducer";
import addressReducer from "./reducers/addressReducer";

const store = configureStore({
  reducer: {
    auth: authReducer,
    cart: cartReducer,
    products: productReducer,
    orders: orderReducer,
    review: reviewReducer,
    wishlist: wishlistReducer,
    filters: filterReducer,
    site: siteReducer,
    categories: categoriesReducer,
    address: addressReducer,
  },
});

export default store;
