import axios from 'axios';
import { API_URL } from '../variables';

export const GET_ADDRESS_REQUEST = 'GET_ADDRESS_REQUEST';
export const GET_ADDRESS_SUCCESS = 'GET_ADDRESS_SUCCESS';
export const GET_ADDRESS_FAILURE = 'GET_ADDRESS_FAILURE';

export const ADD_ADDRESS_REQUEST = 'ADD_ADDRESS_REQUEST';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_FAILURE = 'ADD_ADDRESS_FAILURE';

export const UPDATE_ADDRESS_REQUEST = 'UPDATE_ADDRESS_REQUEST';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_FAILURE = 'UPDATE_ADDRESS_FAILURE';

export const DELETE_ADDRESS_REQUEST = 'DELETE_ADDRESS_REQUEST';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_FAILURE = 'DELETE_ADDRESS_FAILURE';


export const getAddressbyUserId = () => async (dispatch, getState) => {
  dispatch({ type: GET_ADDRESS_REQUEST });

  const { token, user } = getState().auth;

  try {
    const formData = new FormData();
    formData.append('user_id', user.user_id);

    const response = await axios.post(`${API_URL}/api/users/address/get`, formData, {
      headers: { 
        Authorization: `${token}`,
      },
    });
    dispatch({ type: GET_ADDRESS_SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: GET_ADDRESS_FAILURE, payload: error.message });
    throw error;
  }
};

export const addAddress = (address) => async (dispatch, getState) => {
  dispatch({ type: ADD_ADDRESS_REQUEST });
  const { token } = getState().auth;    
  try {
    const response = await axios.post(`${API_URL}/api/users/address/add`, address, {
      headers: { Authorization: `${token}` },
    });
    dispatch({ type: ADD_ADDRESS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: ADD_ADDRESS_FAILURE, payload: error.message });
  }
};

export const updateAddress = (address) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_ADDRESS_REQUEST });

  const { token } = getState().auth;

  try {
    const response = await axios.put(`${API_URL}/api/users/address/update`, address, {
      headers: { Authorization: `${token}` },
    });
    dispatch({ type: UPDATE_ADDRESS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: UPDATE_ADDRESS_FAILURE, payload: error.message });
  }
};

export const deleteAddress = (address_id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_ADDRESS_REQUEST });
  const { token } = getState().auth;    

  try {
    await axios.delete(`${API_URL}/api/users/address/delete`, {
      headers: { Authorization: `${token}` },
      data: { address_id }
    });
    dispatch({ type: DELETE_ADDRESS_SUCCESS, payload: address_id });
  } catch (error) {
    dispatch({ type: DELETE_ADDRESS_FAILURE, payload: error.message });
  }
};










