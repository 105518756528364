import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../actions/ProductActions.js';
import ProductCard from './ProductCard';
import { useNavigate } from 'react-router-dom';
import { selectBestSellerProducts } from '../selectors/ProductSelector';
import '../styles/bestsellers.css';  
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Loader from './Loader';


const BestSellers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.products);
  const bestSellerProducts = useSelector(selectBestSellerProducts);
  const scrollRef = useRef(null);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  };

  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -300, behavior: 'smooth' });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: 300, behavior: 'smooth' });
  };

  if (loading) return <Loader />;
  if (error) return <div>Error: {error}</div>;

  if (bestSellerProducts.length === 0) {
    return (
      <div className="bestsellers-section">
        <div className="bestsellers-title-and-chevron">
          <h3 className="bestsellers-title">Best Sellers</h3>
        </div>
        <div className="no-products-message">
          No best selling products available at the moment.
        </div>
      </div>
    );
  }

  return (
    <div className="bestsellers-section">
      <div className="bestsellers-title-and-chevron">
        <h3 className="bestsellers-title">Best Sellers</h3>
        <div className="bestsellers-chevrons">
          <button className="nav-arrow" onClick={scrollLeft}><FaChevronLeft /></button>
          <button className="nav-arrow" onClick={scrollRight}><FaChevronRight /></button>
        </div>
      </div>
     <div className="bestsellers-grid" ref={scrollRef}>
        {bestSellerProducts.map((product) => (
          <div
            key={product.product_id}
            className="bestsellers-product-item"
            onClick={() => handleProductClick(product.product_id)}
          >
            <ProductCard product={product} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BestSellers;
