import axios from 'axios';
import { API_URL } from '../variables';

// Action types 
export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

export const FETCH_PRODUCT_BY_ID_REQUEST = 'FETCH_PRODUCT_BY_ID_REQUEST';
export const FETCH_PRODUCT_BY_ID_SUCCESS = 'FETCH_PRODUCT_BY_ID_SUCCESS';
export const FETCH_PRODUCT_BY_ID_FAILURE = 'FETCH_PRODUCT_BY_ID_FAILURE';

export const fetchProducts = (page = 1, pagination = 10, sortBy = '') => async (dispatch) => {
  dispatch({ type: FETCH_PRODUCTS_REQUEST });
  try {
    const response = await axios.post(`${API_URL}/api/products?page=${page}`, {
      pagination,
      sortBy,
      status: 'published',
    });
    dispatch({ type: FETCH_PRODUCTS_SUCCESS, payload: response.data.results });
  } catch (error) {
    dispatch({ type: FETCH_PRODUCTS_FAILURE, payload: error.message });
  }
};

// Action to fetch a product by ID
export const fetchProductById = (id) => async (dispatch) => {
  dispatch({ type: FETCH_PRODUCT_BY_ID_REQUEST });
  try {
    const response = await axios.get(`${API_URL}/api/products/${id}`);
    dispatch({ type: FETCH_PRODUCT_BY_ID_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_PRODUCT_BY_ID_FAILURE, payload: error.message });
  }
};


