import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCategories } from '../actions/categoriesActions';
import { useImage } from '../contexts/ImageContext';
import '../styles/shopCategories.css';
import Loader from './Loader';

const ShopCategories = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getImageUrl } = useImage();
  const { categories, loading } = useSelector((state) => state.categories);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);
  const displayCategories = categories.slice(0, 6).map(category => ({
    title: category.name.toUpperCase(),
    image: getImageUrl(category.image),
    link: `/category/${category.category_id}`
  }));

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="shop-categories">
      <div className="shop-categories-container">
      <h3 className="shopbycategory-title">Shop by Category</h3>
      <div className="shop-categories__grid">
        {displayCategories.map((category, index) => (
          <div 
            key={index}
            className="shop-categories__item"
            onClick={() => navigate(category.link)}
          >
            <div className="shop-categories__image-wrapper">
              <img 
                src={category.image} 
                alt={category.title} 
                className="shop-categories__image"
              />
            </div>
            <h3 className="shop-categories__title">{category.title}</h3>
          </div>
        ))}
      </div>
      </div>
    </div>
  );
};

export default ShopCategories;
