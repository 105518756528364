import React, { useState } from "react";
import { AiOutlineHeart } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { removeFromCart, updateCart } from "../actions/cartActions"; 
import "../styles/cartitem.css";
import { formatMoney } from "./formatMoney";

const CartItem = ({ product, cartItemId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isRemoving, setIsRemoving] = useState(false);
  const [localQuantity, setLocalQuantity] = useState(product.quantity);
  const [itemTotal, setItemTotal] = useState(product.price * product.quantity);

  const handleQuantityChange = (change) => {
    const newQuantity = localQuantity + change;
    if (newQuantity >= 1) {
      setLocalQuantity(newQuantity);
      setItemTotal(product.price * newQuantity);
      dispatch(updateCart({
        cart_item_id: cartItemId,
        product_id: product.product_id,
        quantity: newQuantity
      }));
    }
  };

  const handleBuyNow = () => {
    navigate('/checkout', {
      state: {
        singleItem: {
          product_id: product.product_id,
          quantity: localQuantity,
          price: Number(product.price),
          name: product.name,
          imageUrl: product.imageUrl
        }
      }
    });
  };

  const handleRemoveClick = () => {
    setIsRemoving(true);
    dispatch(removeFromCart(cartItemId));
  };

  return (
    <div className={`cart-item ${isRemoving ? 'fade-out' : ''}`}>
      <img src={product.imageUrl} alt={product.name} className="cart-item-image" />
      <div className="cart-item-details">
        <h4 className="cart-item-name">{product.name}</h4>
        <div className="cart-item-description">{product.description}</div>
        <div className="cart-item-price-section">
          <span className="cart-item-price">
            {formatMoney(itemTotal)}
            {localQuantity > 1 && (
              <span className="cart-item-unit-price">
                ({formatMoney(product.price)} each)
              </span>
            )}
          </span>
          {product.discountedPrice && (
            <span className="cart-item-discounted-price">
              {formatMoney(product.discounted_price * localQuantity)}
            </span>
          )}
        </div>
        <div className="cart-item-actions">
          <div className="cart-item-quantity-controls">
            <button className="quantity-decrease" onClick={() => handleQuantityChange(-1)} disabled={localQuantity <= 1}>-</button>
            <span className="cart-item-quantity">
              {localQuantity }
            </span>
            <button 
              className="quantity-increase"
              onClick={() => handleQuantityChange(1)}
            >
              +
            </button>
          </div>
          <button 
            className="cart-buy-now-button"
            onClick={handleBuyNow}
          >
            Buy Now
          </button>
        </div>
      </div>
      <div className="cart-item-icons">
        <AiOutlineHeart className="icon-heart green" />
        <div className="cart-item-remove">
          <FaTimes 
            className="cart-item-remove-icon"
            onClick={handleRemoveClick}
          />
        </div>
      </div>
    </div>
  );
};

export default CartItem;
