import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/ordersummary.css";
import { formatMoney } from "./formatMoney";

const OrderSummary = ({ cartTotal = 0, orders = [] }) => {
  const location = useLocation();
  const isCartPage = location.pathname === "/cart";
  const singleItem = location.state?.singleItem;
  const total = singleItem 
    ? singleItem.price * singleItem.quantity 
    : (orders.length > 0 
        ? orders.reduce((sum, order) => sum + Number(order.total_price), 0)
        : cartTotal);


  return (
    <div className="order-summary">
      <h4>Order Summary</h4>
      <div className="summary-detail">
        <span>Subtotal:</span>
        <span>{formatMoney(total)}</span>
      </div>
      <div className="summary-detail">    
        <span>Tax:</span>
        <span>FREE</span>
      </div>

      <div className="summary-total">
        <strong>Total:</strong>
        <strong>{formatMoney(total)}</strong>
      </div>

      {isCartPage && (
        <Link to="/checkout">
          <button className="checkout-button">Proceed to Checkout</button>
        </Link>
      )}
    </div>
  );
};

export default OrderSummary;
