import axios from 'axios';
import { API_URL } from '../variables';
export const PLACE_ORDER_REQUEST = 'PLACE_ORDER_REQUEST';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE';

export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE';

export const UPDATE_ORDER_STATUS_REQUEST = 'UPDATE_ORDER_STATUS_REQUEST';
export const UPDATE_ORDER_STATUS_SUCCESS = 'UPDATE_ORDER_STATUS_SUCCESS';
export const UPDATE_ORDER_STATUS_FAILURE = 'UPDATE_ORDER_STATUS_FAILURE';

export const PLACE_CUSTOM_ORDER_REQUEST = 'PLACE_CUSTOM_ORDER_REQUEST';
export const PLACE_CUSTOM_ORDER_SUCCESS = 'PLACE_CUSTOM_ORDER_SUCCESS';
export const PLACE_CUSTOM_ORDER_FAILURE = 'PLACE_CUSTOM_ORDER_FAILURE'; 


export const placeOrder = (orderData) => async (dispatch, getState) => {
    dispatch({ type: PLACE_ORDER_REQUEST });
    const { token } = getState().auth; 

    try {
        const response = await axios.post(`${API_URL}/api/orders/place`, orderData, {
            headers: {
                Authorization: `${token}`,  
            },
        });
        dispatch({ type: PLACE_ORDER_SUCCESS, payload: response.data });
        return response.data;  // Return the response data to make the Promise work
    } catch (error) {
        dispatch({ type: PLACE_ORDER_FAILURE, payload: error.message });
        throw error;  // Re-throw the error to be caught by the caller
    }
};
export const getOrdersByUserId = () => async (dispatch, getState) => {
    dispatch({ type: GET_ORDERS_REQUEST });

    const { user, token } = getState().auth;

    try {
        const response = await axios.post(
            `${API_URL}/api/orders/user`, 
            { user_id: user.user_id }, 
            {
                headers: { Authorization: `${token}` },
            }
        );
        console.log('Fetched orders response:', response.data); // Add this log

        dispatch({ type: GET_ORDERS_SUCCESS, payload: response.data.orders || response.data});
    } catch (error) {
        console.error('Error fetching orders:', error);
        dispatch({ type: GET_ORDERS_FAILURE, payload: error.message });
    }
};
export const updateOrderStatus = (orderData) => async (dispatch, getState) => {
    dispatch({ type: UPDATE_ORDER_STATUS_REQUEST });

    const { token } = getState().auth;

    try {
        const response = await axios.post(`${API_URL}/api/orders/update`, orderData, {
            headers: {
                Authorization: `${token}`,
            },
        });
        dispatch({ type: UPDATE_ORDER_STATUS_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: UPDATE_ORDER_STATUS_FAILURE, payload: error.message });
    }
};
export const PlaceCustomOrder = (orderData) => async (dispatch, getState) => {
    dispatch({ type: PLACE_CUSTOM_ORDER_REQUEST });

    const { token } = getState().auth;

    try {
        const response = await axios.post(`${API_URL}/api/orders/custom-place`, orderData, {
            headers: { Authorization: `${token}` },
        });
        dispatch({ type: PLACE_CUSTOM_ORDER_SUCCESS, payload: response.data });
        return response.data;
    } catch (error) {
        dispatch({ type: PLACE_CUSTOM_ORDER_FAILURE, payload: error.message });
        throw error;
    }

};


