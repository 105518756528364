// Checkout.js
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ContactInfo from '../components/ContactInfo';
import ShippingInfo from '../components/ShippingInfo';
import OrderSummary from '../components/Ordersummary';
import { fetchCart } from '../actions/cartActions';
import { placeOrder } from '../actions/ordersActions';
import { getAddressbyUserId } from '../actions/addressActions';
import { useNavigate } from 'react-router-dom';
import '../styles/checkout.css';
import ProgressIndicator from '../components/ProgressIndicator';

const Checkout = () => {
  const [contactData, setContactData] = useState({});
  const [activeSection, setActiveSection] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { items: cartItems = [] } = useSelector((state) => state.cart);
  const { addresses = [] } = useSelector((state) => state.address);
  const defaultAddress = addresses.find(addr => addr.is_default) || addresses[0];

  const cartTotal = cartItems.reduce(
    (total, item) => total + (parseFloat(item.product.price) || 0) * (item.quantity || 1),
    0
  );

  useEffect(() => {
    dispatch(fetchCart());
    dispatch(getAddressbyUserId());
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!defaultAddress) {
      alert('Please add a shipping address');
      return;
    }

    if (!contactData.primaryPhone) {
      alert('Please add contact information');
      return;
    }

    try {
      const addressString = `${defaultAddress.address}, ${defaultAddress.city}, ${defaultAddress.country}${
        contactData.secondaryPhone ? `, Secondary Phone: ${contactData.secondaryPhone}` : ''
      }`;

      await dispatch(placeOrder({ address: addressString }));
      navigate('/orders');
    } catch (error) {
      console.error('Error placing order:', error);
    }
  };

  const toggleSection = (section) => {
    setActiveSection((prevSection) => (prevSection === section ? null : section));
  };

  const isSectionFilled = (section) => {
    if (section === 'shipping') {
      return !!defaultAddress;
    }
    if (section === 'contact') {
      return !!contactData.primaryPhone;
    }
    return false;
  };

  return (
    <div className="checkout-page">
      <ProgressIndicator currentStep={2} />
      <div className="checkoutPageContainer">
        <form onSubmit={handleSubmit} className="formSectionContainer">
          <div className="formSection checkout-shipping">
            <div className="checkout-section-header" onClick={() => toggleSection('shipping')}>
              <div className="checkout-header">
                <h2 className="checkout-heading">Shipping Information</h2>
                <span className="checkout-section-toggle">
                  {activeSection === 'shipping' ? '-' : '+'}
                </span>
              </div>
              {isSectionFilled('shipping') && (
                <p className="section-summary">
                  {`${defaultAddress.address}, ${defaultAddress.city}, ${defaultAddress.country}, Postal Code: ${defaultAddress.postal_code}`}
                </p>
              )}
            </div>
            {activeSection === 'shipping' && (
              <div className="shippingInfoForm">
                <ShippingInfo />
              </div>
            )}
          </div>
          <div className="formSection checkout-contact">
            <div className="checkout-section-header" onClick={() => toggleSection('contact')}>
              <div className="checkout-header">
                <h2 className="checkout-heading">Contact Information</h2>
                <span className="checkout-section-toggle">
                  {activeSection === 'contact' ? '-' : '+'}
                </span>
              </div>
              {isSectionFilled('contact') && (
                <p className="section-summary">
                  {`Primary: ${contactData.primaryPhone || ''}${
                    contactData.secondaryPhone ? ` • Secondary: ${contactData.secondaryPhone}` : ''
                  }`}
                </p>
              )}
            </div>
            {activeSection === 'contact' && (
              <div className="contactInfoForm">
                <ContactInfo data={contactData} setData={setContactData} />
              </div>
            )}
          </div>
          <button 
            type="submit" 
            className="placeorder-submitButton"
            disabled={!isSectionFilled('shipping') || !isSectionFilled('contact')}
          >
            Place Order
          </button>
        </form>
        <div className="orderSummarySection">
          <OrderSummary cartTotal={cartTotal} />
        </div>
      </div>
    </div>
  );
};

export default Checkout;
