import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'; 
import '../styles/imageslider.css'; 
import image1 from '../assets/images/slider1.jpg';
import image2 from '../assets/images/slider2.jpg';
import image3 from '../assets/images/slider3.jpg';
import image4 from '../assets/images/slider4.jpg';
import image5 from '../assets/images/slider11.jpg';
import image6 from '../assets/images/slider15.jpg';
import image7 from '../assets/images/slider16.jpg';
import image8 from '../assets/images/slider7.jpg';
import image9 from '../assets/images/slider8.jpg';
import image10 from '../assets/images/slider9.jpg';
import image11 from '../assets/images/slider10.jpg';

const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11]; 

const slideContent = [
  {
    title: "Discover\nKorean Skincare",
    subtitle: "Pure Zen Cleanser | E5 Eternal Glow | Radishield Sun Cream",
    image: image1
  },
  {
    title: "Perfect Your\nComplexion",
    subtitle: "Eternal MatteBlend | Pure Balance | Dusk Delight",
    image: image2
  },
  {
    title: "Deep Collagen\nStarter Kit",
    subtitle: "Your First Step to Glass Skin",
    image: image3
  },
  {
    title: "TIRTIR\nGift Set",
    subtitle: "Luxury Skincare Collection",
    image: image4
  },
  {
    title: "Collagen\nHydration",
    subtitle: "Medicube PDRN Pink Collagen Gel Mask",
    image: image5
  },
  {
    title: "Luxurious\nLip Collection",
    subtitle: "Chroma Beauty | Secret Glow | Silhouette Liner",
    image: image6
  },
  {
    title: "Essential\nBeauty Tools",
    subtitle: "Professional Brush Sets | Beauty Tools",
    image: image7
  },
  {
    title: "New Year\nArrivals",
    subtitle: "Latest Beauty Innovations",
    image: image8
  },
  {
    title: "Special\nOffers",
    subtitle: "Up to 60% Off Selected Items",
    image: image9
  },
  {
    title: "Premium\nK-Beauty",
    subtitle: "Luxury Korean Cosmetics",
    image: image10
  },
  {
    title: "Luxury\nGift Sets",
    subtitle: "Curated Beauty Collections",
    image: image11
  }
];

const ImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(images.length - 1);
  const [isHovered, setIsHovered] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const navigate = useNavigate(); 

  useEffect(() => {
    let interval;
    
    if (isPlaying && !isHovered) {
      interval = setInterval(() => {
        setPrevIndex(currentIndex);
        setCurrentIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }, 3000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isHovered, isPlaying, currentIndex]);

  const goToPrevious = () => {
    setPrevIndex(currentIndex);
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setPrevIndex(currentIndex);
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleShopNowClick = () => {
    navigate("/productspage");
  };

  return (
    <div
      className="slider"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {slideContent.map((content, index) => (
        <div
          key={index}
          className={`slide ${index === currentIndex ? 'active' : index === prevIndex ? 'prev' : ''}`}
          style={{ backgroundImage: `url(${content.image})` }}
        >
          <div className="slider-content">
            <span className="slider-tag">New Arrival</span>
            <h2>{content.title}</h2>
            <p>{content.subtitle}</p>
            <button className="shop-now-btn" onClick={handleShopNowClick}>
              shop now
            </button>
          </div>
        </div>
      ))}

      <div className="slider-controls">
        <button className="arrow-btn" onClick={goToPrevious}>‹</button>
        <div className="slide-counter">{currentIndex + 1} / {images.length}</div>
        <button className="arrow-btn" onClick={goToNext}>›</button>
        <button className="play-pause-btn" onClick={() => setIsPlaying(!isPlaying)}>
          {isPlaying ? "II" : "▶"}
        </button>
      </div>
    </div>
  );
};

export default ImageSlider;
