import React, { useEffect } from 'react';  
import { useDispatch, useSelector } from 'react-redux'; 
import { useNavigate } from 'react-router-dom'; 
import OrderSummary from '../components/Ordersummary'; 
import { getOrdersByUserId } from '../actions/ordersActions';  
import '../styles/orders.css';   
import AccountLayout from '../components/AccountLayout';
import formatMoney from '../components/formatMoney';

const OrdersPage = () => {     
    const dispatch = useDispatch();     
    const navigate = useNavigate();     
    const { isAuthenticated, user } = useSelector(state => state.auth);     
    const { orders = [], loading, error } = useSelector(state => state.orders);      

    useEffect(() => {         
        if (!isAuthenticated) {             
            navigate('/signin');         
        } else if (user?.user_id) {             
            dispatch(getOrdersByUserId(user.user_id));          
        }     
    }, [dispatch, isAuthenticated, navigate, user]);      

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
    };

    const handleOrderClick = (orderId) => {
        navigate(`/orders/${orderId}`); 
    };

    return (         
        <div className="myaccount-container">        
            <AccountLayout />     
            <div className="orders-container">        
                <h2 className="orders-title">My Orders</h2>             
                {loading && <p className="loading-message">Loading your orders...</p>}             
                {error && <p className="error-message">{error}</p>}             
                {orders.length === 0 && !loading && <p className="no-orders-message">You have no orders.</p>}                          
                {orders.length > 0 && (
                    <div className="orders-list">                 
                        <div className="order-item-header">
                            <div className="order-item-cell-header">Order ID</div>
                            <div className="order-item-cell-header">Date</div>
                            <div className="order-item-cell-header">Status</div>
                            <div className="order-item-cell-header">Total Price</div>
                            <div className="order-item-cell-header">Payment Status</div>
                            <div className="order-item-cell-header">Total Products</div>
                        </div>
                        {orders.map(order => (                     
                            <div key={order.order_id} className="order-item" onClick={() => handleOrderClick(order.order_id)}>
                                <div className="order-item-cell order-id">
                                    {order.order_id.substring(0, 8)}...
                                </div>
                                <div className="order-item-cell">
                                    <span className="orderdate">{formatDate(order.created_at)}</span>
                                </div>
                                <div className="order-item-cell">
                                    <span className="order-status">{order.status}</span>
                                </div>
                                <div className="order-item-cell">{formatMoney(order.total_price)}</div>
                                <div className="order-item-cell">
                                    <span className="order-payment-status">{order.payment_status}</span>
                                </div>
                                <div className="order-item-cell">{order.total_products}</div>
                                <div className="order-item-cell">
                                    <button 
                                        className={`order-item-cell-button ${order.payment_status === 'paid' ? 'paid' : ''}`}
                                    >
                                        {order.payment_status === 'paid' ? 'View Details' : 'Pay Now'}
                                    </button>
                                </div>
                            </div>                 
                        ))}              
                    </div>
                )}                          
                {orders.length > 0 && <OrderSummary orders={orders} />}         
            </div>     
        </div>     
    ); 
};  

export default OrdersPage;
