import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import "../styles/productlist.css";
import ProductCard from "./ProductCard";
import Loader from "./Loader";
import { API_URL } from '../variables';

const ProductList = () => {
  const { categoryId } = useParams();  
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoryName, setCategoryName] = useState(''); 
  const [filteredProducts, setFilteredProducts] = useState([]);

  // Get filters from Redux store
  const filters = useSelector(state => state.filters);
  // Fetch products
  useEffect(() => {
    const fetchProductsByCategory = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_URL}/api/products/category/${categoryId}/`);
        const fetchedProducts = response.data.products || [];
        setProducts(fetchedProducts);
        setFilteredProducts(fetchedProducts); // Initially show all products
        setCategoryName(response.data.name || ''); 
      } catch (error) {
        console.error("Error fetching products:", error);
        setProducts([]); 
        setFilteredProducts([]);
      } finally {
        setLoading(false);
      }
    };
    
    if (categoryId) {
      fetchProductsByCategory();
    }
  }, [categoryId]);  

  // Apply filters when they change
  useEffect(() => {
    if (!products.length) return;

    console.log("Filters changed:", filters);
    
    const filtered = products.filter(product => {
      // Price Filter
      const basePrice = parseFloat(product.base_price) || 0;
      const discountedPrice = parseFloat(product.discounted_price) || basePrice;
      const finalPrice = discountedPrice || basePrice;
      
      let shouldInclude = true;

      // Only apply price filter if the range is different from default
      if (filters.priceRange.min !== 0 || filters.priceRange.max !== 1000) {
        shouldInclude = finalPrice >= filters.priceRange.min && 
                       finalPrice <= filters.priceRange.max;
      }

      // Category filter
      if (filters.category.length > 0) {
        shouldInclude = shouldInclude && 
          filters.category.includes(product.category?.name);
      }

      // Skin type filter
      if (filters.skinType.length > 0) {
        shouldInclude = shouldInclude && 
          product.skin_type && filters.skinType.includes(product.skin_type);
      }

      // Skin concern filter
      if (filters.skinConcern.length > 0) {
        shouldInclude = shouldInclude && 
          product.features?.some(feature => filters.skinConcern.includes(feature));
      }

      // Brand filter
      if (filters.brand.length > 0) {
        shouldInclude = shouldInclude && 
          product.name && filters.brand.includes(product.name);
      }

      // Rating filter
      if (filters.rating > 0) {
        shouldInclude = shouldInclude && 
          (parseFloat(product.product_rating) || 0) >= filters.rating;
      }

      return shouldInclude;
    });

    console.log("Filtered products:", filtered);
    setFilteredProducts(filtered);
  }, [filters, products]);

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  return (
    <div className="products">
      <div className="list-header">
        <div className="list-header-title">
          <h2>{categoryName || 'Products'}</h2> 
        </div>
        <div className="sort-options">
          <div>
            <span>{filteredProducts.length} items</span>
            <span>|</span>
            <span>view 24 36 48</span>
          </div>
          <select>
            <option value="new">New</option>
            <option value="best">Best</option>
          </select>
        </div>
      </div>

      {loading ? (
        <div className="loader-container">
          <Loader />
          <p>Loading products...</p>
        </div>
      ) : filteredProducts.length > 0 ? (
        <div className="products-grid">
          {filteredProducts.map((product) => (
            <div key={product.product_id} onClick={() => handleProductClick(product.product_id)}>
              <ProductCard product={product} />
            </div>
          ))}
        </div>
      ) : (
        <div className="no-products">
          <p>No products available</p>
        </div>
      )}
    </div>
  );
};

export default ProductList;
