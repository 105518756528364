// src/selectors/ProductSelector.js
import { createSelector } from 'reselect';

const getProductsState = (state) => state.products;
const getFilters = (state, filters) => filters;

export const selectProducts = createSelector(
  [getProductsState],
  (productsState) => productsState.products || []
);

export const selectFilteredProducts = createSelector(
  [selectProducts, getFilters],
  (products, filters) => {
    const { 
      skin_type, 
      skin_concern, 
      brand, 
      keywords, 
      formulation, 
      features,
      priceRange 
    } = filters;

    return products.filter((product) => {
      const matchesSkinType = skin_type.length === 0 || 
        (product.skin_type && product.skin_type.some(type => skin_type.includes(type)));

      const matchesSkinConcern = skin_concern.length === 0 || 
        (product.skin_concern && product.skin_concern.some(concern => skin_concern.includes(concern)));

      const matchesBrand = brand.length === 0 || 
        (product.brand && brand.includes(product.brand));

      const matchesKeywords = keywords.length === 0 || 
        (product.keywords && product.keywords.some(keyword => keywords.includes(keyword)));

      const matchesFormulation = formulation.length === 0 || 
        (product.formulation && product.formulation.some(form => formulation.includes(form)));

      const matchesFeatures = features.length === 0 || 
        (product.features && product.features.some(feature => features.includes(feature)));

      const matchesPrice = !priceRange.min && !priceRange.max ? true :
        parseFloat(product.base_price) >= priceRange.min && 
        parseFloat(product.base_price) <= priceRange.max;

      return matchesSkinType && 
             matchesSkinConcern && 
             matchesBrand && 
             matchesKeywords && 
             matchesFormulation && 
             matchesFeatures && 
             matchesPrice;
    });
  }
);

export const selectBestSellerProducts = createSelector(
  [selectProducts],
  (products) => {
    return products.filter((product) => {
      return product.tags && 
        product.tags.includes('BEST SELLER');
    });
  }
);

export const selectNewProducts = createSelector(
  [selectProducts],
  (products) => {
    return products.filter((product) => {
      return product.tags && 
        product.tags.includes('NEW ARRIVAL');
    });
  }
);

export const SelectTrendingProducts = createSelector(
  [selectProducts],
  (products) => {
    return products.filter((product) => {
      return product.tags && 
        product.tags.includes('HOT DEAL');
    });
  }
);
